import MainAbout from "../MainAbout";
import Footer from "../../Home/Footer";

export default function About() {
    return (
        <>
        <MainAbout />
        <Footer />
        </>
    );
}