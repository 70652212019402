import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";


const SoldTag = ({ sold }) => {
  if(sold == true) {
    return (<p className="gallery-sold-tag" style={{ color: 'var(--primary)', fontWeight: '1000' }}> SOLD </p>)
  }
  else{
    return;
  }
}


const SaleTag = ({ item }) => {
  if(item.sale == false && item.category == 'Originals') 
  {
   return (<p>${item.price}</p>);
  }
  else if (item.sale == false && item.category == 'Prints')
  {
    return (
      <>
      <p> $10 - 80</p>
      </>
    )
  }
  else {
    const discountPrice = item.price - item.discount / 100 * item.price;
    return (
      <>
      <p> <a style={{textDecorationLine: 'line-through', marginRight: '2px' }}> ${item.price}</a>
      <a style={{color:'var(--primary)'}}> ${discountPrice}</a></p>
      </>
    )
  }
}

const GalleryItem = ({ item }) => (

  <Link className="item--link" to={`/item/${item.id}`}>
    <div id={item.category} className="gallery-item">
      <img src={item.image} alt={item.title} />
      <h3>{item.title}</h3>
      <SaleTag item={item} />
      <SoldTag sold={item.sold}/>
    </div>
  </Link>
);

const Gallery = ({ data}) => {
  
  return (
    <div className="gallery-container">
      <div className="gallery">
        {data.map((item) => (
          <GalleryItem key={item.category} item={item}/>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
