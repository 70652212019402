export default function HeroSection() {
    return (
    <section id="heroSection" className="hero--section">
        <div className="hero--section--content--box">
            <div className="hero--section--content">
                <h1 className="hero--section--title">
                    <span className="hero--section--title--colour"> 
                    "Beauty in vulnerability"
                    </span>
                </h1>
                <p className="hero--section--description">
                Inspired by a passion for life, the beauty of nature, and a desire to create.  Many<br />
                pieces feature female subjects with natural elements, expressing emotional and <br />
                physical vulnerability.

                </p>
            </div>
        </div>
    </section>
    );   
}