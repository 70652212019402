import React from 'react';
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Gallery from '../Gallery';
import Footer from '../../Home/Footer';

const GalleryPage = ({ data }) => {
    const { category } = useParams();

    const filteredData = data.filter((item) => item.category === category);
  
    const updateFilter = () => {
        filteredData = data.filter((item) => item.category === category);
    }

    useEffect ( () =>  {
            updateFilter;
        }
    );


    updateFilter;


  return (
    <>
      <Gallery key={category} data={filteredData}  />
      <Footer />
    </>
  );
};

export default GalleryPage;