export default function Header() {
    return (
        <section id="headerSeciton" >
            <div className="header--content" >
                <div className="header--image--box"> 
                    <img className= "header--image" src = {require ('../../img/Main Page/Header-main.jpg')} />
                </div>
                <div className="header--text">
                    <h1> <span>Ashley.M. <br/> Champagne </span></h1>
                </div>
            </div>
        </section>
    )
}