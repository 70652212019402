export default function BundleFeature() {
    return (
        <div className="feature1--content--box">
            <div className="feature1--text--container">
                <h1 className="feature1--title"> Print Bundle Deal!</h1>
                <h1 className="feature1--price"> $50.00 </h1>
                <p className="feature1--description">
                    Three 8.5" x 11" prints <br/>
                    and three 5" x 7" prints
                    <br/>
                    <br/>
                    If interested, please contact to <br/>
                    discuss payment and shipping options.
                </p>
            </div>
            <div className="feature1--photo--container">
                <img className="feature1--image" src={require("../../img/PrintsDeal.jpg")} alt="Logo" />
            </div>
            
        </div>
    );

}