import Header from "../Header";
import HeroSection from "../HeroSection";
import BundleFeature from "../BundleFeature";
import Feature1 from "../Feature1";
import OriginalMarquee from "../OriginalMarquee";
import Feature2 from "../Feature2";
import PrintsMarquee from "../PrintsMarquee";
import Footer from "../Footer";


export default function Home() {
    return (
        <>
        <Header />
        <HeroSection />
        <BundleFeature />
        <Feature1 />
        <OriginalMarquee />
        <Feature2 />
        <PrintsMarquee />
        <Footer />
        </>
    );
}