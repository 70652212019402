export default function Feature1() {
    return (
        <div className="feature1--content--box">
            <div className="feature1--photo--container">
                <img className="feature1--image" src={require("../../img/Main Page/Marquee Prints/Freckled-Moths-Prints.jpg")} alt="Logo" />
            </div>
            <div className="feature1--text--container">
                <h1 className="feature1--title"> " Freckled Moths" <br /> prints</h1>
                <h1 className="feature1--price"> $10.00 - $80.00 </h1>
            </div>
        </div>
    );

}