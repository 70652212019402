
import './App.css';
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './Pages/Home/Homescreen'; 
import About from './Pages/About/Aboutscreen';
import ContactScreen from './Pages/ContactMe/ContactScreen';
import Nav from './Pages/Home/Nav';
import GalleryPage from './Pages/Gallery/GalleryScreen';
import CommissionPage from './Pages/Commissions/CommissionsScreen';
import ItemPage from './Pages/Items/ItemScreen';



const App = () => {

  const galleryData = [

    //Originals
    { id: 'Wild', title: 'Wild', price: 1700.00, image: require('./img/Originals/Wild.jpg'), category: 'Originals', dimension:'24" x 36"', sold: false, sale: true, discount: 25 },
    { id: 'Find Yourself', title: 'Find Yourself', price: 1500.00, image: require('./img/Originals/Find Yourself.jpg'), category: 'Originals', dimension:'24" x 30"', sold: false, sale: true, discount: 25},
    { id: 'Super Nova', title: 'Super Nova', price: 1000.00, image: require('./img/Originals/Super Nova.jpg'), category: 'Originals', dimension:'20" x 24"' , sold: false, sale: true, discount: 25},
    { id: 'Bloom', title: 'Bloom', price: 1000.00, image: require('./img/Originals/Bloom.jpg'), category: 'Originals', dimension: '18" x 24"', sold: false, sale: true, discount: 25},
    { id: 'Ocean Eyes', title: 'Ocean Eyes', price: 700.00, image: require('./img/Originals/Ocean Eyes.jpg'), category: 'Originals', dimension: '15" x 30"' , sold: false, sale: true, discount: 25},
    { id: 'Cover Me In Nature', title: 'Cover Me In Nature', price: 800.00, image: require('./img/Originals/Cover Me In Nature.jpg'), category: 'Originals', dimension: '18" x 24"', sold: false, sale: true, discount: 25},
    { id: 'Paper Leaves', title: 'Paper Leaves', price: 1000.00, image: require('./img/Originals/Paper Leaves.jpg'), category: 'Originals', dimension: '20" x 24"', sold: false , sale: true, discount: 25},
    { id: 'Head In The Stars', title: 'Head In the Stars', price: 700.00, image: require('./img/Originals/Head In The Stars.jpg'), category: 'Originals', dimension:'20" x 20"', sold: false, sale: true , discount: 25},
    { id: 'Metamorphosis', title: 'Metamorphosis', price: 1000.00, image: require('./img/Originals/Metamorphosis.jpg'), category: 'Originals', dimension:'20" x 24"', sold: false, sale: true, discount: 25},
    { id: 'Natures Enticing Call', title: 'Natures Enticing Call', price: 800.00, image: require('./img/Originals/Natures Enticing Call.jpg'), category: 'Originals', dimension: '18" x 24"', sold: false, sale: true, discount: 25 },
    { id: 'Natural Element', title: 'Natural Element', price: 800.00, image: require('./img/Originals/Natural Element.jpg'), category:'Originals', dimension: '16" x 20"', sold: false, sale: true, discount: 25 },
    { id: 'Freckled Moths', title: 'Freckled Moths', price: 600.00, image: require('./img/Originals/Freckled Moths.jpg'), category: 'Originals', dimension: '16" x 20"', sold: false, sale: true, discount: 25  },
    { id: 'A Taste of Moonlight', title: 'A Taste of Moonlight', price: 600.00, image: require('./img/Originals/A Taste of Moonlight.jpg'), category: 'Originals', dimension:'12" x 24"', sold: false, sale: true, discount: 25 },
    { id: 'Flowering', title: 'Flowering', price: 500.00, image: require('./img/Originals/Flowering.jpg'), category: 'Originals', dimension: '16" x 20"', sold: false, sale: true, discount: 25},
    { id: 'Blue Gaze', title: 'Blue Gaze', price: 800.00, image: require('./img/Originals/Blue Gaze.jpg'), category: 'Originals', dimension:'18" x 24"', sold: false, sale: true, discount: 25 },
    { id: 'Sunflowers', title: 'Sunflowers', price: 600.00, image: require('./img/Originals/Sunflowers.jpg'), category: 'Originals',dimension: '10" x 20"' , sold: false, sale: true, discount: 25},
    { id: 'She Is Everything', title: 'She Is Everything', price: 600.00, image: require('./img/Originals/She is Everything.jpg'), category: 'Originals', dimension:'18" x 24"' , sold: false, sale: true, discount: 25},
    { id: 'Colors of Me', title: 'Covers of Me', price: 150.00, image: require('./img/Originals/Colors of Me.jpg'), category: 'Originals', dimension:'10" x 10"' , sold: false, sale: true, discount: 25},
    { id: 'Mount Ever Rest', title: 'Mount Ever Rest', price: 1500.00, image: require('./img/Originals/Mount Ever Rest.jpg'), category: 'Originals', dimension:'24" x 48"' , sold: false, sale: true, discount: 25},
    { id: 'Dont Go', title: 'Don\'t Go', price: 400.00, image: require('./img/Originals/Dont Go.jpg'), category: 'Originals', dimension:'12" x 16"' , sold: true, sale: false, discount: 0},
    { id: 'Breeze Through Me', title: 'Breeze Through Me', price: 500.00, image: require('./img/Originals/Breeze Through Me.jpg'), category: 'Originals', dimension:'16" x 20"', sold: false, sale: true, discount: 25 },
    { id: 'Deep Space', title: 'Deep Space', price: 200.00, image: require('./img/Originals/Deep Space.jpg'), category: 'Originals', dimension:'12"' , sold: false, sale: true, discount: 25},
    { id: 'Morning Glow', title: 'Morning Glow', price: 800.00, image: require('./img/Originals/Morning Glow.jpg'), category: 'Originals', dimension:'18" x 24"' , sold: false, sale: true, discount: 25},
    { id: 'Silent Observer', title: 'Silent Observer', price: 300.00, image: require('./img/Originals/Silent Observer.jpg'), category: 'Originals', dimension:'16" x 20"', sold: false, sale: true, discount: 25 },
    { id: 'Voilet Dreams', title: 'Violet Dreams', price: 700.00, image: require('./img/Originals/Violet Dreams.jpg'), category: 'Originals', dimension:'24" x 36"', sold: true, sale: false, discount: 0 },
    
    //Prints
    { id: 'Metamorphosis Prints', title: '"Metamorphosis" Prints', price: '$12.00 - $40.00', image: require('./img/Prints/Metemorphosis Prints.jpg'), category: 'Prints', dimension:'', sale: false, discount: 0  },
    { id: 'Paper Leaves Prints', title: '"Paper Leaves" Prints', price: '$12.00 - $40.00', image: require('./img/Prints/Paper Leaves Prints.jpg'), category: 'Prints', dimension:'', sale: false, discount: 0  },
    { id: 'Super Nova Prints', title: '"Super Nova" Prints', price: '$12.00 - $40.00', image: require('./img/Prints/Super Nova Prints.jpg'), category: 'Prints', dimension:'', sale: false , discount: 0 },
    { id: 'A Taste of Moonlight Prints', title: '"A Taste of Moonlight" Prints', price: '$12.00 - $40.00', image: require('./img/Prints/A Taste of Moonlight Prints.jpg'), category: 'Prints', dimension:'', sale: false , discount: 0 },
    { id: 'Cover Me In Nature Prints', title: '"Cover Me In Nature" Prints', price: '$12.00 - $40.00', image: require('./img/Prints/Cover Me in Nature Prints.jpg'), category: 'Prints', dimension:'', sale: false, discount: 0  },
    { id: 'Natural Element Prints', title: '"Natural Element" Prints ', price: '$12.00 - $40.00', image: require('./img/Prints/Natural Element Prints.jpg'), category: 'Prints', dimension:'', sale: false, discount: 0  },
    { id: 'She Is Everything Prints', title: '"She Is Everything" Prints', price: '$12.00 - $40.00', image: require('./img/Prints/She is Everything Prints.jpg'), category: 'Prints', dimension:'', sale: false, discount: 0  },
    { id: 'Nature Within Prints', title: '"Nature Within" Prints', price: '$12.00 - $40.00', image: require('./img/Prints/Nature Within Prints.jpg'), category: 'Prints', dimension:'', sale: false, discount: 0  },
    { id: 'Head In The Stars Prints', title: '"Head In The Stars" Prints', price: '$12.00 - $40.00', image: require('./img/Prints/Head in the Stars Prints.jpg'), category: 'Prints', dimension:'', sale: false , discount: 0 },
    { id: 'Freckled Moths Prints', title: '"Freckled Moths" Prints', price: '$12.00 - $40.00', image: require('./img/Prints/Freckled Moths Prints.jpg'), category: 'Prints', dimension:'', sale: false , discount: 0 },
    { id: 'Faded Prints', title: '"Faded" Prints', price: '$12.00 - $40.00', image: require('./img/Prints/Faded Prints.jpg'), category: 'Prints', dimension:'', sale: false, discount: 0  },
    
  ];

  return (
    <div className="App">
      <Nav/>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/contact" element={<ContactScreen />}> </Route>
        <Route path="/gallery/:category" element={<GalleryPage data={galleryData}  />}></Route>
        <Route path="/item/:itemId" element={<ItemPage data={galleryData}/>}></Route>
        <Route path="/commissions" element={<CommissionPage />}></Route>
        <Route path="*" element={<div>404 Not Found</div>}></Route>
      </Routes>
    </div>
  );
}

export default App;
