export default function Footer() {
    return (
      <div className="footer--content--box">
        <div className="footer--logo--container">
          <img className="footer--logo--image" src={require("../../img/Main Page/Main-logo.png")} alt="Logo" />
        </div>
        <div className="footer--copyright--container">
          <h1 className="footer--copyright">&copy; 2023</h1>
        </div>
      </div>
    );
  }