
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";



const Item = ({item}) => {

    const Sold = () => {
        if(item.sold == true) {
            return (
                <p className="sold--tag" style={{ color: 'var(--primary)', fontWeight: '1000', fontFamily: '"Nanum Myeongjo", serif'}}>
                    SOLD
                </p>
            )
        }
        else {
            return;
        }
    }

    const Message = () => {
        if(item.category == 'Prints') {
            return (
                <div className="prints--description" style={{ display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems:'center', textAlign:'center' }}>
                    <p>If interested, click the link below to see print options on INPRNT</p>
                    <a className="btn btn-outline-primary" href="https://www.inprnt.com/gallery/a.m.champagne/" style={{ display: 'flex', justifyContent: 'center', alignItems:'center', textAlign:'center', textDecoration: 'none', backgroundColor:'var(--primary)',color: 'var(--white)', fontSize: 'min(4vw, 4vh)', textAlign: 'center',fontFamily: '"Nanum Myeongjo", serif' }}>
                        INPRNT
                    </a> 
                </div>
            );
        }
        else {
            return (
                <div className="originals--description">
                    <Sold />
                    <p>If interested, please contact me to discuss payment and shipping options, thanks!</p>
                    <Link to="/contact" style={{display: 'flex', justifyContent: 'center', textDecoration: 'none'}}>
                        <button className="btn btn-primary contact--form--btn">Get In Touch</button>
                    </Link>
                </div>
            );
        }
    
    }
    
    const DiscountPrice = () => {
        if(item.category == 'Originals')
        {
            const price = item.price - item.discount / 100 * item.price;
            return(
            <>
                    <h3>${price}</h3>
                </>
            );
        }
        else 
        {
            return (
                <>
                </>
            );
        }
    }


    return (
        <div className="item--container">
            <div className="item--image--container">
            <img className="item--image" src={item.image} />
            </div>
            <div className="item--details--container">
                <h2>{item.title}</h2>
                <DiscountPrice />
                <h3>{item.dimension}</h3>

                <Message className="item--message"/>
                
            </div>
        </div>
    );
}

export default Item