export default function MainAbout() {
    return(
        <section id="AboutScreen">
            <div className="about--content--box">
                <div className="about--image--box">
                  <img className="about--image" src={require("../../img/About/About-Me-Image.jpg")} />
                </div>
                <div className="about--description--box">
                    <p class Name="about--description">
                        I'm a self-taught Canadian artist since 2017, currently living in Vancouver BC, working with acrylics, graphite and watercolor. 
                        <br />
                        <br />
                        My work is inspired by a passion for life, empowered by the beauty of nature, and a desire to create. 
                        <br />
                        <br />
                        Many of my pieces feature female subjects with natural elements, expressing emotional and physical vulnerability.
                        <br />
                        <br />
                        “Beauty in vulnerability”
                        <br />
                        <br />
                        Follow my Instagram for progression and studio photos, concept sketches and completed works.
                    </p>
                    <a href="https://www.instagram.com/ashley.m.champagne/">@ashley.m.champagne</a>
                </div>
            </div>
        </section>
    );
}