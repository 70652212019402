import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const navItems = [
  { label: 'Home', to: '/' },
  { label: 'Originals', to: '/gallery/Originals' },
  { label: 'Prints', to: '/gallery/Prints' },
  { label: 'About', to: '/about' },
  { label: 'Commissions', to: '/commissions'} 
];



const Nav = () => {
  const [navActive, setNavActive] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const location = useLocation();

  const DropdownMenu = () => (
    <div className={`dropdown-menu ${dropdownVisible ? "active" : ""}`}>
      <ul className="dropdown-navbar-items">
        {navItems.map((item, index) => (
          <ul key={index}>
            <Link
              onClick={() => {
                closeMenu();
                toggleDropdown();
              }}
              to={item.to}
            >
              {item.label}
            </Link>
          </ul>
        ))}
        <ul>
          <Link 
          onClick={() => {
            closeMenu();
            toggleDropdown();
          }}
          to='/contact'
          >
            Contact
          </Link>
          </ul>
      </ul>
    </div>
  );

  const toggleNav = () => {
    setNavActive(!navActive);
    setDropdownVisible(false);
  };

  const closeMenu = () => {
    setNavActive(false);
    setDropdownVisible(false);
  };

  const toggleDropdown = () => {
    console.log("Toggling Dropdown");
    setDropdownVisible(!dropdownVisible);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500 && navActive) {
        closeMenu();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [navActive]);

  useEffect(() => {
    if (window.innerWidth <= 1200 && navActive) {
      closeMenu();
    }
  }, [navActive]);
  useEffect(() => {
    // Scroll to the top when the location changes
    window.scrollTo(0, 0);
  }, [location]);


  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrolledDown = prevScrollPos < currentScrollPos;
      const isScrolledUp = prevScrollPos > currentScrollPos;
      const isStationary = prevScrollPos === currentScrollPos;

      if (
        (isScrolledDown && visible && currentScrollPos > 200) ||
        (isScrolledUp && !visible && currentScrollPos <= 200)
      ) {
        setVisible(!isStationary);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos, visible]);

  const navbarClass = `navbar ${navActive ? "active" : ""} ${visible ? "visible" : "hidden"} ${
    prevScrollPos > 200 ? "scrolled" : ""
  } ${location.pathname !== "/" ? "solid-color" : ""}`;

  return (
    <nav className={navbarClass}>
      <div>
        <Link
          onClick={closeMenu}
          activeClass="navbar--active-content"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          to="/"
          className="navbar--content"
        >
          <img className="nav--logo--image" src={require("../../img/Main Page/Main-logo.png")} alt="Logo" />
        </Link>
      </div>
      <a
        className={`nav__hamburger ${navActive ? "active" : ""}`}
        onClick={toggleDropdown}
      >
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
      </a>
      <div className={`navbar--items ${navActive ? "active" : ""}`}>
        <ul>
          {navItems.map((item, index) => (
            <li key={index}>
              <Link
                onClick={closeMenu}
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to={item.to}
                className="navbar--content"
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="dropdown-container">
      
        {dropdownVisible && <DropdownMenu />}
      </div>
      <Link
        onClick={closeMenu}
        activeClass="navbar--active-content"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        to="/contact"
        className="btn btn-outline-primary"
      >
        Contact Me
      </Link>
    </nav>
  );
};

export default Nav;