import Contact from "../Contact";
import Footer from "../../Home/Footer";

export default function ContactScreen() {
    return (
        <>
            <Contact />
            <Footer />
        </>
    );
}

