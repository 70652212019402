import React from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../../Home/Footer';
import Item from '../Item';

const ItemPage = ({data})  => {
    const { itemId } = useParams();
    
    const selectedItem = data.find((item) => String(item.id) === itemId);
    
    if (!selectedItem) {
      return <div>Item not found</div>;
    }

    return (
        <>
        <Item item = {selectedItem}/>
        <Footer/>
        </>
    )
}

export default ItemPage;