import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";


const MarqueeItem = ({item}) => {
    return (
        <Link className="item--link" to={`/item/${item.id}`}>
            <div className="marquee-item" key={item.category}>
                <img src={item.image}  />
                <h1 className="marquee--original--title">{item.title} <br /> {item.price}</h1>
            </div>
        </Link>
    )
}

const PrintsMarquee = () => {
  const marqueeItems = [
    { id: 'Metamorphosis Prints', title: '"Metamorphosis" Prints', price: '', image: require('../../img/Prints/Metemorphosis Prints.jpg'), category: 'Prints', dimension:'' },
    { id: 'Paper Leaves Prints', title: '"Paper Leaves" Prints', price: '', image: require('../../img/Prints/Paper Leaves Prints.jpg'), category: 'Prints', dimension:'' },
    { id: 'Super Nova Prints', title: '"Super Nova" Prints', price: '', image: require('../../img/Prints/Super Nova Prints.jpg'), category: 'Prints', dimension:'' },
    { id: 'A Taste of Moonlight Prints', title: '"A Taste of Moonlight" Prints', price: '', image: require('../../img/Prints/A Taste of Moonlight Prints.jpg'), category: 'Prints', dimension:'' },
    { id: 'Cover Me In Nature Prints', title: '"Cover Me In Nature" Prints', price: '', image: require('../../img/Prints/Cover Me in Nature Prints.jpg'), category: 'Prints', dimension:'' },
    { id: 'Natural Element Prints', title: '"Natural Element" Prints ', price: '', image: require('../../img/Prints/Natural Element Prints.jpg'), category: 'Prints', dimension:'' },
    { id: 'She Is Everything Prints', title: '"She Is Everything" Prints', price: '', image: require('../../img/Prints/She is Everything Prints.jpg'), category: 'Prints', dimension:'' },
    { id: 'Head In The Stars Prints', title: '"Head In The Stars" Prints', price: '', image: require('../../img/Prints/Head in the Stars Prints.jpg'), category: 'Prints', dimension:'' },
  ];
  return (
    <><div>
          <h1 className="original--marque--title"> Prints </h1>
      </div><div className="scrollable-marquee-container">
              <div className="scrollable-marquee">
                  {marqueeItems.map((item, index) => (
                      <MarqueeItem item = {item} />
                  ))}
              </div>
          </div></>
  );
}
export default PrintsMarquee;