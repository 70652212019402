
import { Link, useLocation } from "react-router-dom";

export default function Commission() {
    return (
    <div className="commission-container">
        <h1> Commission Paintings </h1>
        <p>
        
        If you're interested in a personalized painting, please message below the details of what you are looking for (size, color scheme, themes, any other relevant details).
        I will review your request and let you know if I am able to accept.<br/><br/>

        Deposits are required, 50% before your piece is started, and the other 50% upon completion.<br/><br/>

        Commissions can take up to 3 months to complete, and photo references are required.<br/><br/>

        Please keep in mind the style/themes of my work.<br/><br/>

        Prices listed below are for one subject (person), multiple subjects will increase the price.<br/><br/>

        (Prices may vary depending on requests)<br/><br/>
        </p>
        <div className='commission--list'>
        <ul>12" x 15": $300</ul>
        <ul>12" round: $300</ul>
        <ul>12" x 24": $400</ul>
        <ul>16" round: $400</ul>
        <ul>16" x 20": $500</ul>
        <ul>15" x 30": $600</ul>
        <ul>18" x 24": $600</ul>
        <ul>20" x 24": $700</ul>
        <ul>24: x 30": $900</ul>
        </div>
        <Link to="/contact" className="btn btn-outline-primary">Contact Me</Link>
    </div>
    );
}