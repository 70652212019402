import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";

const MarqueeItem = ({item}) => {

    const discountPrice = item.price - item.discount / 100 * item.price;

    return (
        <Link className="item--link" to={`/item/${item.id}`}>
            <div className="marquee-item" key={item.category}>
                <img src={item.image}  />
                <h1 className="marquee--original--title">{item.title} <br /> 
                <a style={{color: 'var(--primary)'}}>${discountPrice}</a></h1>
            </div>
        </Link>
    )
}

function OriginalMarquee() {
  const marqueeItems = [
    
    { id: 'Find Yourself', title: 'Find Yourself', price: 1500.00, image: require('../../img/Originals/Find Yourself.jpg'), category: 'Originals', dimension:'24" x 30"', discount: 25 },
    { id: 'Super Nova', title: 'Super Nova', price: 1000.00, image: require('../../img/Originals/Super Nova.jpg'), category: 'Originals', dimension:'20" x 24"', discount: 25 },
    { id: 'Bloom', title: 'Bloom', price: 1000.00, image: require('../../img/Originals/Bloom.jpg'), category: 'Originals', dimension: '18" x 24"', discount: 25},
    { id: 'Cover Me In Nature', title: 'Cover Me In Nature', price: 800.00, image: require('../../img/Originals/Cover Me In Nature.jpg'), category: 'Originals', dimension: '18" x 24"', discount: 25},
    { id: 'Head In The Stars', title: 'Head In the Stars', price: 700.00, image: require('../../img/Originals/Head In The Stars.jpg'), category: 'Originals', dimension:'20" x 20"', discount: 25 },
    { id: 'Metamorphosis', title: 'Metamorphosis', price: 1000.00, image: require('../../img/Originals/Metamorphosis.jpg'), category: 'Originals', dimension:'20" x 24"', discount: 25 },
    { id: 'She Is Everything', title: 'She Is Everything', price: 600.00, image: require('../../img/Originals/She is Everything.jpg'), category: 'Originals', dimension:'18" x 24"' , discount: 25},
    
  ];
  return (
    <><div>
          <h1 className="original--marque--title"> Originals </h1>
      </div><div className="scrollable-marquee-container">
              <div className="scrollable-marquee">
                  {marqueeItems.map((item, index) => (
                      <MarqueeItem item={item} />
                  ))}
              </div>
          </div></>
  );
}


export default OriginalMarquee;