export default function Feature1() {
    return (
        <div className="feature1--content--box">
            <div className="feature1--photo--container">
                <img className="feature1--image" src={require("../../img/Main Page/Natural-Element.jpg")} alt="Logo" />
            </div>
            <div className="feature1--text--container">
                <h1 className="feature1--title"> Natural Element</h1>
                <h1 className="feature1--price"> <a style={{textDecorationLine: 'line-through'}}>$800.00</a> <a style={{color:'var(--primary)'}}> $600.00</a> </h1>
            </div>
        </div>
    );

}