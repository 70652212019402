import Commission from "../Commission";
import Footer from "../../Home/Footer";

export default function CommissionPage() {
    return (
        <>
        <Commission />
        <Footer />
        </>
    );
}